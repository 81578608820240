import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { injectGlobal } from 'emotion'
import Link from 'gatsby-link'
import { navigate } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import 'typeface-merriweather'
import 'typeface-teko'
import { styles } from '../styles/styles.jsx'
import { FaFacebook, FaInstagram, FaInstagramSquare, FaTwitter, FaLinkedin } from 'react-icons/fa'

injectGlobal`
  html {
    font-size: 100%;
  }
  body {
    margin: 0px;
    font-family: Merriweather;
    font-weight: 100;
  } 
  h1,h2,h3,h4,h5,h6 {
    font-family: Teko;
    font-weight: 300;
  }
`

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsSite {
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
        datoCmsContactInfo {
          businessName
          contactInfo
        }
      }
    `}
    render={data => (
      <div className={styles.footer}>
        <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />

        <div className={styles.container}>
          <div className={styles.footerContainer}>
            <div className={styles.footerLinks}>
              <Link to={'/'} className={styles.footerBrand}>
                {data.datoCmsContactInfo.businessName}
              </Link>
              <div
                className={
                  styles.footerContactInfo + ' ' + styles.footerContactInfoInner
                }
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsContactInfo.contactInfo,
                }}
              />
            </div>
            <div className={styles.footerConnect}>
              <span
                className={styles.portfolioLink}
                onClick={() => navigate('/contact')}
                style={{ borderWidth: '1px', cursor: 'pointer' }}
              >
                Connect
              </span>
              <div className={styles.footerSocial}>
                <FaInstagram
                  size={'3rem'}
                  className={styles.footerSocialIcon}
                  onClick={() =>
                    navigate(
                      'https://www.instagram.com/monxdh'
                    )
                  }
                  style={{ color: '#C6D27E', cursor: 'pointer' }}
                />
                <FaLinkedin
                  size={'3rem'}
                  className={styles.footerSocialIcon}
                  onClick={() =>
                    navigate(
                      'https://www.linkedin.com/company/monx-design-house'
                    )
                  }
                  style={{ color: '#C6D27E', cursor: 'pointer' }}
                />
                <FaTwitter
                  size={'3rem'}
                  className={styles.footerSocialIcon}
                  onClick={() =>
                    navigate(
                      'https://twitter.com/monxdesignhouse'
                    )
                  }
                  style={{ color: '#C6D27E', cursor: 'pointer' }}
                />
                <FaFacebook
                  size={'3rem'}
                  className={styles.footerSocialIcon}
                  onClick={() =>
                    navigate(
                      'https://www.facebook.com/pages/Monx-Design-House/100857603313920'
                    )
                  }
                  style={{ color: '#C6D27E', cursor: 'pointer' }}
                />
              </div>
            </div>
          </div>
          <div className={styles.footerCopyright}>
            <p>Copyright © Monx Design House 2020</p>
          </div>
        </div>
      </div>
    )}
  />
)

export default Footer
