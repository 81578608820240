import tw from '../../tailwind.js'
import { css } from 'emotion'

export const styles = {
  background: css(tw`bg-accent`),
  backgroundColor: css(`
    position: absolute;
    background-color: rgba(0,0,0, 0.45);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  `),
  header: css(tw`pb-24 relative`),
  headerContainer: css(
    tw`mx-auto max-w-xl flex flex-col justify-center items-center z-10`
  ),
  navigation: css(tw`bg-accent py-2 flex justify-between`),
  container: css(tw`mx-auto max-w-xl relative xs:top-0`),
  spacer: css(tw`h-12 lg:h-0`),
  brand: css(
    `
    max-width: 300px;
    min-width: 200px;
    width: 100%;
    `
  ),
  brandPadding: css(tw`py-32`),
  caseStudyBgOverlay: css(`
    background-image: linear-gradient(-90deg,rgba(70,78,71,0.00) 10%,#464E47 65%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    `),
  caseStudyContainer: css(
    tw`w-full lg:w-1/2 px-5 lg:px-0 my-3 lg:my-24 py-3 lg:py-24`
  ),
  caseStudyTag: css(tw`text-accent m-0 block`),
  caseStudyTitle: css(
    tw`uppercase text-white text-7xl m-0 leading-none inline-block`
  ),
  caseStudyDescription: css(tw`text-white text-lg inline-block`),
  caseStudyLink: css(
    tw`uppercase text-accent border-accent border-t-1 border-solid py-3 px-5 mt-5 inline-block`
  ),
  contactForm: css(`
  input,
  textarea {
    padding: 8px 8px;
    margin: 0 -8px;
    background: transparent;
    text-transform: uppercase;
    width: 100%;
    font-size: 1rem;
  }
  button {
    text-transform: uppercase;
    color: #464E47;
    background-color: #C6D27E;
    cursor: pointer;
  }
  textarea {
  }
  input,
  textarea {
    &::placeholder {
      opacity: 1;
      // color: white !important;
    }
  }
  input,
  textarea
 {
    color: #fff;
    font-family: Merriweather;
    background-color: transparent;
    border: solid 1px #fff;
    ::placeholder {
      color: #fff;
    }
    &:active,
    &:focus,
    &:hover {
      border: solid 1px #fff;
    }
  }
  `),
  contactPageBackgroundImage: css(`
    &:after, &:before {
      background-position: center center;
    }
    `),
  contactFormContainer: css(tw`flex flex-wrap`),
  contactFormInput: css(tw`w-full lg:w-1/2 block font-merriweather`),
  contactFormInputPadding: css(tw`py-3 px-5 block`),
  contactFormMessage: css(tw`w-full`),
  contactFormButton: css(
    tw`w-full py-3 px-5 font-merriweather font-bold text-lg`
  ),
  contactFormButtonContainer: css(tw`w-full  py-3 px-3`),
  contactTitle: css(tw`text-white uppercase text-7xl block m-0 px-3`),
  contactText: css(tw`text-white text-lg block mt-0 px-3`),
  contactContainer: css(tw`w-full lg:w-1/2 py-24`),
  contentOuterContainer: css(tw`bg-lightGray`),
  contentContainer: css(
    tw`bg-white shadow-md p-10 w-full flex flex-wrap lg:flex-wrap`
  ),
  contentInner: css(tw`w-full lg:w-full leading-loose`),
  contentAuthor: css(tw`w-full lg:w-full p-3 flex`),
  contentAuthorPortrait: css(tw`w-1/3`),
  contentAuthorAttribution: css(tw`flex flex-col justify-center w-2/3 p-5`),
  contentAuthorName: css(tw`uppercase text-4xl m-0`),
  contentAuthorTitle: css(tw`font-barbershop text-white m-0`),
  contentAuthorBg: css(
    `background-image: linear-gradient(90deg, #C6D27E 0%, rgba(198,210,126,0.00) 100%);`
  ),
  divider: css(`
    height: 2px;
    width: 100%;
    z-index: 1;
    background-color: white;
  `),
  instaPosts: css(tw`flex flex-wrap`),
  instaPostsWrapper: css(tw`inline-flex w-full flex-col lg:flex-row my-5 flex-wrap`),
  instaPostContainer: css(tw`w-full md:w-1/3 relative my-5`),
  instaPostInnerWrapper: css(tw`h-full overflow-hidden px-5`),
  instaPostMetaWrapper: css(tw`flex flex-col bottom-0 w-full bg-accent h-full`),
  instaPostMetaInner: css(tw`w-full bg-accent`),
  instaPostInfoWrapper: css(tw`flex flex-row px-2 pt-2`),
  instaPostLikes: css(tw`text-white pr-3`), 
  instaPostComments: css(tw`text-white`), 
  instaPostCaption: css(tw`text-white`),
  menu: css(tw`flex pt-5 z-10`),
  navButton: css(tw`block`),
  link: css(
    tw`text-white text-sm lg:text-base no-underline p-2 lg:p-5 block uppercase`
  ),
  quoteSliderContainer: css(tw`w-full`),
  team: css(tw`p-10 bg-white`),
  teamContent: css(tw`w-full md:w-full lg:w-full xl:w-full px-0 lg:px-5`),
  teamTitle: css(
    tw`text-primary uppercase text-7xl leading-none m-0 pt-5 xl:pt-0 px-5 xl:px-0`
  ),
  teamDescription: css(tw`leading-loose p-5 xl:p-0`),
  teamContainerOuter: css(tw`mx-auto max-w-2xl`),
  teamContainer: css(
    tw`mx-auto max-w-2xl ml-0 xl:ml-16 mt-0 xl:mt-16 flex flex-col-reverse sm:flex-col-reverse md:flex-col-reverse lg:flex-row xl:flex-row`
  ),
  teamContainerBg: css(`
    z-index: 1;
    &:before {
      opacity: 0 !important;
    }
    &:after {
      opacity: .2 !important;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      @media only screen and (min-width: 1200px) {
        border-right: solid white 2rem;
        border-bottom: solid white 2rem; 
      }
    }
  `),
  teamMember: css(tw`flex flex-col sm:flex-row text-left flex-wrap lg:mt-10`),
  teamMemberReverse: css(tw`flex flex-col sm:flex-row-reverse text-right flex-wrap lg:mt-10`),
  teamMemberBg: css(
    `background-image: linear-gradient(-90deg, #C6D27E 0%, rgba(198,210,126,0.00) 100%);`
  ),
  teamMemberBgReverse: css(
    `background-image: linear-gradient(90deg, #C6D27E 0%, rgba(198,210,126,0.00) 100%);`
  ),
  teamMemberImage: css(tw`w-full sm:w-1/2 lg:w-1/4`),
  teamMemberContent: css(
    tw`w-full sm:w-1/2 lg:w-3/4 text-white flex flex-col justify-end p-5`
  ),
  teamMemberDescription: css(tw`w-full p-10`),
  teamMemberDescriptionFont: css(tw`leading-loose text-sm mt-5`),
  teamMemberName: css(tw`text-7xl uppercase text-primary m-0`),
  teamMemberTitle: css(tw`font-barbershop text-3xl leading-none m-0`),
  missionBgOverlay: css(`
    background-image: linear-gradient(90deg,rgba(70,78,71,0.00) 30%,#464E47 75%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    `),
  missionBgOverlayDisplay: css(tw`hidden xl:block`),
  missionContainer: css(tw`flex justify-end py-4 lg:py-16 my-4 lg:my-24`),
  missionContent: css(tw`w-full xl:w-1/2 px-5 xl:px-0`),
  missionTitle: css(tw`text-white text-7xl uppercase my-0`),
  missionText: css(tw`text-white leading-loose`),
  portfolio: css(tw`m-10 text-primary `),
  featuredBgOverlay: css(`
    background-image: linear-gradient(-90deg,rgba(70,78,71,0.00) 10%,#464E47 65%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    `),
  featuredContainer: css(
    tw`flex flex-col w-full lg:w-1/2 px-5 lg:px-0 my-3 lg:my-24 py-3 lg:py-24`
  ),
  featuredTag: css(tw`text-accent m-0 block`),
  featuredTitle: css(
    tw`uppercase text-white text-7xl m-0 leading-none inline-block`
  ),
  featuredDescription: css(tw`text-white text-lg inline-block`),
  featuredLink: css(
    tw`uppercase text-accent border-accent border-t-1 border-solid py-3 px-5 mt-5 inline-block`
  ),
  footer: css(tw`bg-primary py-5 pt-10`),
  footerContainer: css(tw`p-5 flex flex-wrap justify-between`),
  footerConnect: css(tw`w-full lg:w-2/3 flex flex-col justify-between items-center lg:items-end pt-10 lg:pt-0`),
  footerLinks: css(tw`w-full lg:w-1/3`),
  footerBrand: css(tw`no-underline text-accent`),
  // footerContactInfo: css(tw`leading-none text-white`),
  footerContactInfo: css(`
    color: white;
    a {
      color: white;
      text-decoration: none;
    }
  `),
  footerCopyright: css(css`
    margin: 1rem 0 0 0;
    color: white;
    text-align: center;
    border-top: solid 1px #d8d8d8;
    font-size: 14px;
  `),
  videoWrapper: css(`
    z-index: -1;
    position: absolute;
    height: 100%;
  `),
  footerSocialIcon: css(tw`
  px-3 
`),
  footerSocial: css(tw`
    flex 
  `),
  workContainer: css(tw`inline-flex w-full flex-col lg:flex-row my-5 flex-wrap`),
  workContainerOuter: css(tw`py-5 lg:py-10`),
  workItemContainer: css(
    tw`w-full xl:w-1/2 flex flex-col md:flex-row m-0 mt-5 relative pl-5 xs:pl-5 lg:pl-0 pr-5`
  ),
  workItem: css(
    tw`w-full xl:w-full flex flex-col md:flex-row shadow-md m-0 mt-5 relative`
  ),
  workImage: css(tw`h-32 sm:h-auto w-full md:w-3/8 z-10`),
  workImageBg: css(`
    z-index: 1;
    &:after {
      background-position: center center;
    }
    `),
  workContent: css(tw`w-2/3 p-5`),
  workTag: css(tw`uppercase text-gray font-normal font-special m-0`),
  workTitle: css(tw`text-7xl uppercase text-center m-0`),
  workItemTitle: css(tw`text-3xl leading-none my-1`),
  workDescription: css(tw`my-1 mb-2 text-gray`),
  workLink: css(tw`text-accent no-underline`),
  portfolioLink: css(
    tw`uppercase text-accent border-accent border-t-1 border-solid py-3 px-5 block mb-5 lg:mb-0`
  ),
  buttonContainer: css(tw`flex justify-center my-5`),
  logoTitle: css(tw`text-7xl text-center uppercase m-0`),
  logoContainer: css(tw`py-10`),
  largeTitle: css(tw`text-5xl m-0 uppercase`),
}
